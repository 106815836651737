import Vue from 'vue';
import FlashApp from '../flash/app.vue';

import Cookies from 'js-cookie';

import '../flash/snotify';

window.Cookies = Cookies;

document.addEventListener('DOMContentLoaded', () => {
  const container = document.createElement('div');
  container.id = 'flash_app';
  document.body.appendChild(container);

  const flashTypeToSnotifyFunctionMap = {
    success: 'success',
    notice: 'info',
    alert: 'warning',
    error: 'error',
  };

  const app = new Vue({
    el: container,
    components: { FlashApp },
    template: '<FlashApp/>',
  });

  window.flashApp = {
    $snotify: app.$snotify,

    flashHandler: () => {
      if (typeof Cookies === 'undefined') return;

      const cookie = Cookies.get('flash');

      if (!cookie) return;

      const flashes = JSON.parse(cookie);
      window.flashApp.pushFlashes(flashes);

      Cookies.remove('flash');
    },

    pushFlashes(flashes) {
      let type;
      for (type in flashes) {
        const text = decodeURIComponent(flashes[type]);
        const functionName = flashTypeToSnotifyFunctionMap[type];

        if (functionName) this.$snotify[functionName](text);
      }
    },
  };
});

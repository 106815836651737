import Vue from 'vue';
import Snotify, { SnotifyPosition } from 'vue-snotify';

// import 'vue-snotify/styles/material.css'
import './styles/snotify.scss'

const options = {
  global: {
    maxOnScreen: 4
  },

  toast: {
    position: SnotifyPosition.rightTop,
    timeout: 10000,
    bodyMaxLength: 1000
  }
};

Vue.use(Snotify, options);
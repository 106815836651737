<template>
  <div id="flash_app">
    <template v-if="debug">
      <a href="/flash_test/error" class="btn btn-danger my-2">Test danger</a>
      <button class="btn btn-info my-2" @click="testRequest('notice')">Test remote info</button>
      <button class="btn btn-warning my-2" @click="testRequest('alert')">Test remote warning</button>
      <button class="btn btn-success my-2" @click="testRequest('success')">Test remote success</button>
    </template>
    <vue-snotify />
  </div>
</template>

<script>

  export default {
    data() {
      return {
        debug: false
      };
    },

    methods: {
      testRequest(type) {
        $.get('/flash_test/' + type);
      }
    }
  };
</script>